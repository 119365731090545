import React, {useState} from 'react';
import { commonStyles, mobileStyles } from '../styles';
import { ReactComponent as SoapyLogoSVG } from '../graphics/soapy-logo.svg';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {postServerData} from '../api';
import ThankYouOverlay from './ThankYouOverlay';
import { useTranslation } from 'react-i18next';


const ForgotPassword = ({isMobile}) => { 
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [loginError, setLoginError] = useState('');
    const [showThankYou, setShowThankYou] = useState(false);
    const navigate = useNavigate();
    const styles = isMobile ? mobileStyles : commonStyles;
    
    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleResetClick = async () =>{
        try {
            // Basic form validation
            if (!email) {
                setLoginError(t('messages.email_required'));
                return;
            }

            // Email validation
        if (!isEmailValid(email)) {
            setLoginError(t('messages.invalid_email'));
            return;
        }

            const body = {
                email_address: email
            }

            const data = await postServerData(JSON.stringify(body), 'signin/resetpassword');

            if (data && data.toString().includes('Error')) {                
                setLoginError(t('messages.password_reset_error'));
            } else if (data) {
                setShowThankYou(true);
                setTimeout(() => {
                setShowThankYou(false);
                    navigate('/');                    
                }, 2000);  
            } else {
                setLoginError(t('messages.invalid_email'));
            }
        } catch(error) {
            console.error('Error during password reset:', error);
            setLoginError(t('messages.password_reset_error'));
        }

    };

    const handleBackClick = () =>{
        navigate('/');
    };

    const renderForgotPasswordPage = () =>{
        return (
            <div style={{ ...styles.wizardContainer}}>
                <div style={{ ...styles.wizardElements }}>               
                    <SoapyLogoSVG style={{ ...styles.logo }} />
                    <Typography gutterBottom style={{ marginBottom: 20, marginTop: 14, marginLeft: 1 }}>
                        {t('labels.reset_instructions')}
                    </Typography>
                    <TextField
                        style={{ marginBottom: 20, marginTop: 14 }}
                        type="email"
                        label={t('labels.email')}
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={email==='' && !isEmailValid(email) && loginError } // Update error prop // Add error prop          
                    />
                    <Button style={{ marginTop: 20 }} variant="contained"
                        color="primary" 
                        onClick={handleResetClick}>
                            {t('labels.send_reset_instructions')}
                    </Button>
                    <Link style={{ fontSize:12, marginBottom: 10, marginTop: 2 }} onClick={handleBackClick}>{t('labels.back')}</Link>   
          {loginError && <p style={{ color: 'red' }}>{loginError}</p>}     
                </div>
            </div>
        );
    };

    return (
        <div>
            {renderForgotPasswordPage()}  
            <ThankYouOverlay open={showThankYou} onClose={() => setShowThankYou(false)} text={t('messages.reset_instructions_sent')} />       
        </div>
    )
    
};

export default ForgotPassword;