import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { commonStyles, mobileStyles } from '../styles';
import { ReactComponent as SoapyLogoSVG } from '../graphics/soapy-logo.svg';
import Sidebar from './Sidebar.js';
import { useNavigate, useLocation } from 'react-router-dom';
import ThankYouOverlay from './ThankYouOverlay';
import {postServerData} from '../api';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const Profile = ({isMobile}) => {
    const { t } = useTranslation();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');    
    const [passwordError, setPasswordError] = useState('');
    const location = useLocation();
    const styles = isMobile ? mobileStyles : commonStyles;
    const [showThankYou, setShowThankYou] = useState(false);
    const navigate = useNavigate();
    const authToken = localStorage.getItem('jwtToken');
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(true);

    const verifyLocalToken = useCallback(async () => {
        try {
          if (authToken) {    
            const response = await postServerData('', 'signin/verifytoken',true);           
       
                if (!response && !response.ok) {        
                    throw new Error('Token not present');
                }else {                   
                    setIsAdmin(response.isAdmin);   
                }       
            } else {
                throw new Error('Token not present');                
            }   
        } catch (error) {
          console.error('Token verification failed:', error);
          navigate('/'); // Redirect to login if verification fails
        } finally {
            setIsPageLoading(false);
        }

      }, [authToken, navigate]);
    
      useEffect(() => {
        verifyLocalToken();
      },[verifyLocalToken])
    const handleSavePassword = async () => {
        try {
            // Basic form validation
            if (!password || !confirmPassword ) {       
                setPasswordError(t('messages.all_fields_required'));
                return;
            }
            // Password length validation
            if (password.length < 8) {
                setPasswordError(t('messages.password_length'));
                return;
            }
            // Additional validation, e.g., password matching
            if (password !== confirmPassword) {
                setPasswordError(t('messages.password_mismatch'));
                return;
            }

            const body = {
                password: password                
            }

            const data = await postServerData(JSON.stringify(body), 'signin/changepassword',true);

            if (data && data.toString().includes('Error')) {                
                setPasswordError(t('messsages.signup.failed'));
            } else if (data) {
                setShowThankYou(true);
                setTimeout(() => {
                setShowThankYou(false);
                    navigate('/');                    
                }, 2000);  
            } else {
                setPasswordError(t('messsages.signup.failed'));
            }

        } catch (error) {
            console.error('Error during saving password:', error);
            setPasswordError(t('messages.signup_error'));
        }

    };

    const renderProfilePage = () => {
        return  <div style={{ ...styles.wizardContainer, display: isPageLoading ? 'none' : 'flex' }}>
                    <div style={{ ...styles.wizardElements }}>
                        <Sidebar location={location} isMobile={isMobile} isAdmin={isAdmin}/>
                        <SoapyLogoSVG style={{ ...styles.logo }} />
                        <Typography gutterBottom style={{ marginBottom: 20, marginTop: 14, marginLeft: 1 }}>
                            {t('labels.change_password')}
                        </Typography>
                        <TextField
                            style={{ marginBottom: 20 }}
                            type="password"
                            label={t('labels.password')}
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={!password && passwordError} // Update error prop
                    />
                        <TextField
                            style={{ marginBottom: 20 }}
                            type="password"
                            label={t('labels.confirm_password')}
                            fullWidth
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            error={!confirmPassword && passwordError} // Update error prop
                        />
                        <Button style={{ marginTop: 20 }} variant="contained" color="primary" onClick={handleSavePassword}>
                            {t('labels.save')}
                        </Button>
                        {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}  
                    </div>
                </div>

    }

    return <div>
        {renderProfilePage()}
        <ThankYouOverlay open={showThankYou} onClose={() => setShowThankYou(false)} text={t('labels.saved')} /> ;
    </div>
}

export default Profile;