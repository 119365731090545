import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { commonStyles, mobileStyles } from '../styles';
import { ReactComponent as SoapyLogoSVG } from '../graphics/soapy-logo.svg';
import {api} from '../api.js';
import ThankYouOverlay from './ThankYouOverlay';
import { useNavigate, useParams } from "react-router-dom";
import DesktopNotAvailable from "./DesktopNotAvailable.js";
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';


const SignUp = ({isMobile}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [signupError, setSignupError] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);
  const { signupId } = useParams();
  const [organizationName, setOrganizationName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();

  const styles = isMobile ? mobileStyles : commonStyles;

  const isEmailValid = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignup = async () => {
    try {
      // Basic form validation
      if (!email || !password || !confirmPassword || (!signupId ? !organizationName : '')) {       
        setSignupError(t('messages.all_fields_required'));
        return;
      }

      // Email validation
      if (!isEmailValid(email)) {
        setSignupError(t('messages.invalid_email'));
        return;
      }
      // Password length validation
      if (password.length < 8) {
        setSignupError(t('messages.password_length'));
        return;
      }
      // Additional validation, e.g., password matching
      if (password !== confirmPassword) {
        setSignupError(t('messages.password_mismatch'));
        return;
      }

      // Call the signup API with signup id
      let response ='';
      if (signupId) {
        response = await fetch(api.address + 'signup/user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email_address: email, password, sign_up_id: signupId}),
        });
      } else {
      // Call the signup API with organization name
        response = await fetch(api.address + 'signup/user/admin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email_address: email, password, organization_name: organizationName}),
        });        
      }
      const data = await response.json();

      // Check the response status
      if (response.ok) {      
        setShowThankYou(true);
        setTimeout(() => {
          setShowThankYou(false);
            navigate('/');
               //redirect to login page
        }, 2000); // 2000 milliseconds (3 seconds) delay
      } else {
        setSignupError(data.error || t('messages.signup_failed'));
      }
    } catch (error) {
      console.error('Error during signup:', error);
      setSignupError(t('messages.signup_error'));
    }
  };

  const renderSignUpPage = () => { 
    return (
      <div style={{ ...styles.wizardContainer }}>
        <div style={{ ...styles.wizardElements }}>
          <SoapyLogoSVG style={{ ...styles.logo }} />
          {!signupId && <TextField
            style={{ marginTop: 20 }}
            label={t('labels.organization')}
            fullWidth
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            error={!signupId ? organizationName==='' && signupError : ''} // Update error prop
          />}
          <TextField
            style={{ marginBottom: '20px', marginTop: '20px' }}
            type="email"
            label={t('labels.email')}
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={email==='' && !isEmailValid(email) && signupError} // Update error prop
          />
          <TextField
            style={{ marginBottom: '20px' }}
            type={showPassword ? 'text' : 'password'}
            label={t('labels.password')}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!password && signupError} // Update error prop
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            style={{ marginBottom: '20px' }}
            type={showConfirmPassword ? 'text' : 'password'}
            label={t('labels.confirm_password')}
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!confirmPassword && signupError} // Update error prop
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button style={{ marginTop: '20px' }} variant="contained" color="primary" onClick={handleSignup}>
            {t('labels.signup')}
          </Button>
          {signupError && <p style={{ color: 'red' }}>{signupError}</p>}               
        </div>
      </div>
    );
  };

    return (
            <div>
                {isMobile ? renderSignUpPage() : <DesktopNotAvailable/>}                
                <ThankYouOverlay open={showThankYou} onClose={() => setShowThankYou(false)} text={t('messages.thank_you_for_sign_up')}/>
            </div>
            
        );

};

export default SignUp;
