import React, {useState, useEffect} from 'react';
import { commonStyles, mobileStyles } from '../styles';
import { ReactComponent as SoapyLogoSVG } from '../graphics/soapy-logo.svg';

const DesktopNotAvailable = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

const styles = isMobile ? mobileStyles : commonStyles;
    
    return (
        <div style={{ ...styles.wizardContainer }}>
            <div style={{ ...styles.wizardElements }}>
                <SoapyLogoSVG style={{ ...styles.logo }} />
                <div>Please use mobile device</div>
            </div>
        </div>
    )
};

export default DesktopNotAvailable;