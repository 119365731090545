const commonStyles = {
    wizardContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '40px',
      color: 'rgba(0, 0, 0, 0.6)'      
    },
    wizardElements: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',        
        alignItems: 'center',   
        color: 'rgba(0, 0, 0, 0.6)'     
    },
    logo: {
      maxWidth: '100px',
      marginBottom: '20px',
    },
    input: {
      width: '100%',
      marginBottom: '10px',
    }  
  };
  
  const mobileStyles = {
    logo: {
      height: 60      
    },
    wizardContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px',
        color: 'rgba(0, 0, 0, 0.6)'
        
    },
    wizardElements: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',        
        justifyContent: 'space-between',   
        color: 'rgba(0, 0, 0, 0.6)'     
    },
    multiselect: {
      width:140,
      '& div': {
        marginTop: 5
      }
    },
    sidebarLabels:{
      justifyContent:'flex-start'
    }
  }
  
  export { commonStyles, mobileStyles };
  