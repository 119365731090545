import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { commonStyles, mobileStyles } from '../styles';
import { ReactComponent as SoapyLogoSVG } from '../graphics/soapy-logo.svg';
import {api} from '../api.js';
import DesktopNotAvailable from "./DesktopNotAvailable.js";
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Login = ({isMobile}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const authToken = localStorage.getItem('jwtToken');
  const verifyLocalToken = useCallback(async () => {
    try {      
      if (authToken) {        
          const response = await fetch(api.address + 'signin/verifytoken', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
          },
        });

        if (response && response.ok) {
          setIsPageLoading(false);
          navigate('/wizard');
        }
      } else {
        console.info('Token verification failed or not present');
        return;
      }
    } catch (error) {
      console.info('Token verification failed:', error);
      return;      
    } finally {
      setIsPageLoading(false);
    }
  }, [authToken, navigate]);

    useEffect(() => {
      verifyLocalToken();
    },[verifyLocalToken])

  const styles = isMobile ? mobileStyles : commonStyles;

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = async () => {
    try {     

      // Basic form validation
      if (!email || !password) {
        setLoginError(t('labels.required'));
        return;
      }

      // Email validation
      if (!isEmailValid(email)) {
        setLoginError(t('labels.invalid_email'));
        return;
      }

      setIsLoading(true); // Disable the login button
      // Perform login API call here
      // Replace 'your-login-api-endpoint' with the actual API endpoint for login
      const response = await fetch(api.address + 'signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email_address: email, password }),
      });

      const token = await response.json();

      // Check the response status
      if (token && !token.error) {
        // Login successful, redirect to the wizard page
        // Store the token in local storage
        localStorage.setItem('jwtToken', token);
        navigate('/wizard');
      } else {
        // Login failed, display error message
        setLoginError(token.error);
        setIsLoading(false); // Re-enable the login button
      }
    } catch (error) {
        console.error('Error during login:', error);
        setLoginError(t('labels.error_occured'));
    } finally {
        setIsLoading(false); // Re-enable the login button
    }
  };

  const handleForgotPasswordClick = () => { 
    navigate('/forgotpassword');
  };

  const renderLoginPage = () => {
    return (
      <div style={{ ...styles.wizardContainer, display: isPageLoading ? 'none' : 'flex'  }}>
        <div style={{ ...styles.wizardElements }}>
          <SoapyLogoSVG style={{ ...styles.logo }} />
          <TextField
            style={{ marginBottom: '20px', marginTop: 14 }}
            type="email"
            label={t('labels.email')}
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={email==='' && !isEmailValid(email) && loginError} // Update error prop // Add error prop          
          />
          <TextField           
            type={showPassword ? 'text' : 'password'}
            label={t('labels.password')}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!password && loginError} // Add error prop
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Link style={{ fontSize:12, marginBottom: 10, marginTop: 2 }} onClick={handleForgotPasswordClick}>{t('labels.forgot_password')}</Link>
          <Button style={{ marginTop: '30px' }} variant="contained"
            color="primary" 
            onClick={handleLogin}
            disabled={isLoading}>
            {isLoading ? t('labels.logging_in') : t('labels.login')}        
          </Button>   
          {loginError && <p style={{ color: 'red' }}>{loginError}</p>}       
        </div>
      </div>
    );  
  };

  return (
    <div>
      {isMobile ? renderLoginPage() : <DesktopNotAvailable/> }
    </div>
  );
  
};

export default Login;
