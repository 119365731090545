import React, { useState } from 'react';
import { List, ListItem, Drawer, Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { commonStyles, mobileStyles } from '../styles';

const Sidebar = ({ handleItemClick, location, isAdmin, isMobile }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const styles = isMobile ? mobileStyles : commonStyles;

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClickInternal = (item) => {
    toggleDrawer();
    //handleItemClick(item);
    if (item ==='Report'){
      navigate('/report');
    } else if (item ==='Users'){
      navigate('/users');
    } else if (item ==='Observations'){
      navigate('/wizard');
    } else if (item ==='Profile'){
      navigate('/profile');
    }
  };

  const handleSignOut = () => {
    // Clear the JWT token from local storage
    localStorage.removeItem('jwtToken');
    // Redirect to the login page
    navigate('/');    
  };

  return (
    <>
    <div style={{ position: 'fixed', top: 10, right: 10 }}>
        {/* Hamburger Icon */}
        <Link onClick={toggleDrawer} style={{textDecoration: 'none', fontSize: 22}}>☰</Link>
    </div>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <List>
            <ListItem>
                <Button onClick={() => handleItemClickInternal('Observations')} style={{...styles.sidebarLabels}}>
                  {t('labels.observations')}
                </Button>
            </ListItem>           
            {isAdmin ? <ListItem>                
                <Button onClick={() => handleItemClickInternal('Report')} style={{...styles.sidebarLabels}}>
                  {t('labels.reports')}
                </Button>
            </ListItem>: ''}
            {isAdmin ? <ListItem>
                <Button onClick={() => handleItemClickInternal('Users')} style={{...styles.sidebarLabels}}>
                  {t('labels.users')}
                </Button>
            </ListItem> : '' }
            <ListItem>
                <Button onClick={() => handleItemClickInternal('Profile')} style={{...styles.sidebarLabels}}>
                  {t('labels.profile')}
                </Button>
            </ListItem> 
            <ListItem>                
                <Button onClick={() => handleSignOut()} style={{...styles.sidebarLabels}}>
                  {t('labels.logout')}
                </Button>
            </ListItem>      
        </List>
      </Drawer>     
    </>
  );
};

export default Sidebar;
