import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <select onChange={changeLanguage} style={{ position: 'fixed', top: 16, left: 10, borderColor: '#ffffff', color: '#1976d2' }}>
      <option value="en">🇺🇸</option>
      <option value="it">🇮🇹</option>
      {/* Add more options for other languages */}
    </select>
  );
};

export default LanguageSelector;
