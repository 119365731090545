const api = {
    //address: process.env.API_ADDRESS,
    // eslint-disable-next-line
    address: process.env.REACT_APP_API_ADDRESS + '/api/'
};     

const getServerData = async (endpoint, withToken) => {    

    try {
        const response = await fetch(api.address + endpoint, {
          method: 'GET',
          headers: getHeaders(withToken)
        });

        
        return (await response.json());
    }
    catch (error) {
        console.log('Error sending get request: ' + error.message);
        return 'Error sending get request';
    }
}

const postServerData = async (body, endpoint,withToken) => { 
    try {
        const response = await fetch(api.address + endpoint, {
            method: 'POST',
            headers: getHeaders(withToken),
            body: body,
        });
        
        return (await response.json());
    
    }catch (error) {
        console.log('Error sending post request: ' + error.message);
        return 'Error sending post request';
    }
      
};

const getHeaders = (withToken) => {
    
    const authToken = localStorage.getItem('jwtToken');  

    let headers ={
        'Content-Type': 'application/json'
    };

    if (withToken) {
        headers['Authorization'] = 'Bearer ' + authToken
    }

    return(headers);
};

export {api, getServerData, postServerData };



