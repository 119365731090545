import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import SignUp from './components/SignUp';
import WizardForm from './components/WizardForm';
import Report from './components/Report';
import Access from './components/Access';
import Profile from './components/Profile';
import ForgotPassword from './components/ForgotPassword';
import { I18nextProvider } from 'react-i18next';
import i18n from './translation/i18n';
import LanguageSelector from './components/LanguageSelector';

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <LanguageSelector />
      <Router>
        <Routes>
          <Route path="/" element={<Login isMobile={isMobile}/>} />
          <Route path="/signup/:signupId" element={<SignUp isMobile={isMobile} />} />
          <Route path="/signup" element={<SignUp isMobile={isMobile} />} />
          <Route path="/wizard" element={<WizardForm isMobile={isMobile}/>} />
          <Route path="/report" element={<Report isMobile={isMobile}/>} />
          <Route path="/users" element={<Access isMobile={isMobile}/>} />
          <Route path="/profile" element={<Profile isMobile={isMobile}/>} />
          <Route path="/forgotpassword" element={<ForgotPassword isMobile={isMobile}/>} />
        </Routes>
      </Router>
    </I18nextProvider>
  );
};

export default App;
