import React, { useEffect, useState, useCallback } from 'react';
import { commonStyles, mobileStyles } from '../styles';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { ReactComponent as SoapyLogoSVG } from '../graphics/soapy-logo.svg';
import ThankYouOverlay from './ThankYouOverlay';
import { useNavigate, useLocation } from 'react-router-dom';
import {api} from '../api.js';
import Sidebar from './Sidebar.js';
import { useTranslation } from 'react-i18next';

const WizardForm = ({isMobile}) => {
  const { t } = useTranslation();
  const [unitDropdownData, setUnitDropdownData] = useState([]);
  const [opportunityDropdownData, setOpportunityDropdownData] = useState([]);
  const [observedGroupDropdownData, setObservedGroupDropdownData] = useState([]);
  const [unintDropdownValue, setUnitDropdownValue] = useState('');
  const [opportunityDropdownValue, setOpportunityDropdownValue] = useState('');
  const [observedGroupDropdownValue, setObservedGroupDropdownValue] = useState('');
  const [handHygieneActionValue, setHandHygieneActionValue] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const [errorSendingData, setErrorSendingData] = useState(false);
  const [isMissed, setisMissed] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true); // Assuming isAdmin state is set based on user role
  const [isLoading, setIsLoading] = useState(false);
  const [isProvidedFeedback, setIsProvidedFeedback] = useState(false);


  const navigate = useNavigate();
  const authToken = localStorage.getItem('jwtToken');

  const location = useLocation();

  const verifyLocalToken = useCallback(async () => {
    try {
      if (authToken) {        
        const response = await fetch(api.address + 'signin/verifytoken', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
        },
      });
   
      if (response && response.ok) {        
        const result = await response.json();
        setIsAdmin(result.isAdmin);       
      }
   
    } else {
        throw new Error('Token not present');
      }
    } catch (error) {
      console.error('Token verification failed:', error);
      navigate('/'); // Redirect to login if verification fails
    }
  }, [authToken, navigate]);

  useEffect(() => {
    verifyLocalToken();
  },[verifyLocalToken])


  useEffect(() => {
    const fetchData = async () => {
      try {        
            const response = await fetch(api.address + 'observations/controls', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
            },
          });

        const data = await response.json();

        if (data && data.opportunities && data.departments) {
          setUnitDropdownData(data.departments);
          setOpportunityDropdownData(data.opportunities);
          setObservedGroupDropdownData(data.observed_groups);
        } else if (data.error && data.error === 'Forbidden') {
          console.log('Forbidden, redirecting to login page');
          navigate('/');
        } else {
          console.log('Empty data from data API');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [authToken, navigate]);



  const handleSave = async () => {
    try {
      setErrorSendingData(false);
      if (!unintDropdownValue || !opportunityDropdownValue || !observedGroupDropdownValue || !handHygieneActionValue) {
        setFormValid(false);
      } else {
        setIsLoading(true); // Disable the login button
        // Implement logic to send data to the server and show alert
        const response = await fetch(api.address + 'observations', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`, 
          },
          body: JSON.stringify({ department: unintDropdownValue, opportunity: opportunityDropdownValue, observed_group: observedGroupDropdownValue, hand_hygiene_action: handHygieneActionValue, is_missed: isMissed ? 1 : 0, is_provided_feedback: isProvidedFeedback ? 1 : 0 })
        });
        
        const data = await response.json();

        if (data && data.ok){
          setFormValid(true);
          setShowThankYou(true);
          setTimeout(() => {
            setShowThankYou(false);
            resetForm();
            setIsLoading(false);
          }, 2000); // 2000 milliseconds (2 seconds) delay  
                  
        }else if (data.error && data.error === 'Forbidden') {
          setIsLoading(false);
          console.log('Forbidden, redirecting to login page');
          navigate('/');
        }else{    
          setIsLoading(false);     
          setErrorSendingData(true);
        }       
      }
    }catch (error) {
      console.error('Error during saving data:', error);
      setErrorSendingData(true);
    }finally{
      setIsLoading(false); // Re-enable the login button
    }
  };

  const resetForm = () => {
    setUnitDropdownValue('');
    setisMissed(false);
    setIsProvidedFeedback(false);
    setOpportunityDropdownValue('');
    setHandHygieneActionValue('');
    setObservedGroupDropdownValue(''); 
  };

  const renderWizardPage = () => {
    return (    
      
      <div style={{ ...styles.wizardContainer }}>
          <div style={{ ...styles.wizardElements }}>
            <Sidebar location={location} isAdmin={isAdmin} isMobile={isMobile}/>
            <SoapyLogoSVG style={{ ...styles.logo }} />
            <FormControl fullWidth style={{ marginBottom: 20, marginTop: 14 }}>
              <InputLabel id="department-select-label">{t('labels.care_unit')}</InputLabel>
              <Select
                labelId="departmennt-select-label"
                value={unintDropdownValue}
                onChange={(e) => setUnitDropdownValue(e.target.value)}
                label={t('labels.care_unit')}
                fullWidth
                error={!unintDropdownValue && !formValid}
                style={{ color: 'rgba(0, 0, 0, 0.6)' }}
              >
                {unitDropdownData.map((department) => (
                  <MenuItem key={department.id} style={{ color: 'rgba(0, 0, 0, 0.6)' }} value={department.id}>
                    {t(`departments_drop_down.${department.name}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: 20}}>
              <InputLabel id="department-select-label">{t('labels.observed_group')}</InputLabel>
              <Select
                labelId="departmennt-select-label"
                value={observedGroupDropdownValue}
                onChange={(e) => setObservedGroupDropdownValue(e.target.value)}
                label={t('labels.observed_group')}
                fullWidth
                error={!observedGroupDropdownValue && !formValid}
                style={{ color: 'rgba(0, 0, 0, 0.6)' }}
              >
                {observedGroupDropdownData.map((observed_group) => (
                  <MenuItem key={observed_group.id} style={{ color: 'rgba(0, 0, 0, 0.6)' }} value={observed_group.id}>
                    {t(`observed_groups_drop_down.${observed_group.name}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: '20px' }}>
              <InputLabel id="action-dropdown-label">{t('labels.opportunity')}</InputLabel>
              <Select
                labelId="action-dropdown-label"
                value={opportunityDropdownValue}
                onChange={(e) => setOpportunityDropdownValue(e.target.value)}
                label={t('labels.opportunity')}
                fullWidth
                error={!opportunityDropdownValue && !formValid}
                style={{ color: 'rgba(0, 0, 0, 0.6)' }}
              >
                {opportunityDropdownData.map((opportunity) => (
                  <MenuItem key={opportunity.id} style={{ color: 'rgba(0, 0, 0, 0.6)' }} value={opportunity.id}>
                    {t(`opportunities_drop_down.${opportunity.name}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: '10px' }}>
              <InputLabel id="hand-hygiene-label">{t('labels.hand_hygiene_action')}</InputLabel>
              <Select
                labelId="hand-hygiene-label"
                value={handHygieneActionValue}
                onChange={(e) => setHandHygieneActionValue(e.target.value)}
                label={t('labels.hand_hygiene_action')}
                fullWidth
                error={!handHygieneActionValue && !formValid}
                style={{ color: 'rgba(0, 0, 0, 0.6)' }}
              >              
                  <MenuItem key="Hand Wash" style={{ color: 'rgba(0, 0, 0, 0.6)' }} value="Hand Wash">
                  {t('labels.hand_wash')}
                  </MenuItem>
                  <MenuItem key="Hand Rub" style={{ color: 'rgba(0, 0, 0, 0.6)' }} value="Hand Rub">
                  {t('labels.hand_rub')}
                  </MenuItem>              
              </Select>
            </FormControl>
            <FormControl style={{ marginBottom: '0px' }}>           
              <FormControlLabel
                  control={<Checkbox color="primary" checked={isMissed} onChange={(e) => setisMissed(e.target.checked)} style={{transform: "scale(1.2)"}}/>}
                  label={t('labels.missed')}
                />
            </FormControl>
            <FormControl style={{ marginBottom: '20px' }}>           
              <FormControlLabel
                  control={<Checkbox color="primary" checked={isProvidedFeedback} onChange={(e) => setIsProvidedFeedback(e.target.checked)} style={{transform: "scale(1.2)"}}/>}
                  label={t('labels.provided_feedback')}
                />
            </FormControl>
            <Button disabled={isLoading} onClick={handleSave} variant="contained" color="primary">         
               {isLoading ? t('labels.saving') : t('labels.save')}
            </Button>          
            {!formValid && <p style={{ color: 'red' }}>{t('messages.all_fields_required')}</p>}
            {errorSendingData && <p style={{ color: 'red' }}>{t('labels.error_saving_data')}</p>}         
          </div>
        </div>
     
    );
  };

  const styles = isMobile ? mobileStyles : commonStyles;

  return (
    <div style={{ ...styles.container }}>
      {renderWizardPage()}
      <ThankYouOverlay open={showThankYou} onClose={() => setShowThankYou(false)} text={t('labels.thank_you_message')}/>
    </div>
  );
};

export default WizardForm;
