import React, { useState, useEffect } from 'react';
import { commonStyles, mobileStyles } from '../styles';
import Sidebar from './Sidebar.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as SoapyLogoSVG } from '../graphics/soapy-logo.svg';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';

import { api } from '../api.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Report = ({isMobile}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line
  const [isAdmin, setIsAdmin] = useState(true); // Assuming isAdmin state is set based on user role
  const navigate = useNavigate();
  const location = useLocation();
  const authToken = localStorage.getItem('jwtToken');
  const data = {
    labels:[],
    datasets: [
      {
        label: 'Dataset 1',
        data: [],
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        label: 'Dataset 2',
        data: [],
        backgroundColor: 'rgb(75, 192, 192)',
      }
    ]
  };
  const [barChartData, setBarChartData] = useState();
  const [chartFinalData, setChartFinalData] = useState(data);
  const [unitDropdownData, setUnitDropdownData] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]); 
  const [selectedDateRange, setSelectedDateRange] = useState(-7);

      useEffect(() => {

        const fetchData = async () => {
          try {
            const response = await fetch(api.address + `report?date_range=${selectedDateRange}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
              },
            });

            const data = await response.json();

            if (data && data.observations) {          
                setBarChartData(data.observations);
            } else if (data.error && data.error === 'Forbidden') {
              console.log('Forbidden, redirecting to login page');
              navigate('/');
            } else {
              console.log('Empty data from data API');
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        fetchData();
      }, [authToken, navigate,selectedDateRange]);

  useEffect(() => {
    const fillBarData = () => {      
        if (!barChartData)
            return;

        let dataGroupedBy = barChartData.reduce((accumulator, item) => {
                (accumulator[item.opportunity] = accumulator[item.opportunity] || []).push(item);
                return accumulator;
            }, {});


        let labels = Object.keys(dataGroupedBy);
        
        let datasets = [
        {
          label: t('labels.performed'),
          data: [],
          backgroundColor: 'rgb(46, 118, 210, 0.7)',
          borderColor: 'rgb(46, 118, 210, 1)',
          borderWidth: 0.5

        },
        {
          label: t('labels.missed'),
          data: [],
          backgroundColor: 'rgb(46, 118, 210, 0.3)',
          borderColor: 'rgb(46, 118, 210, 1)',
          borderWidth: 0.5
        }
      ]

      labels.forEach((opportunity) => {
        let data = dataGroupedBy[opportunity].reduce((accumulator, item) => {
          Object.keys(item).forEach(key => {
            if (key !== 'opportunity') {
              accumulator[key] = (accumulator[key] || 0) + item[key];
            }
          });
          return accumulator;
        }, {});
       
        datasets[0].data.push(data.performed); //preformed
        datasets[1].data.push(data.missed); //missed
      });

      let labelsTranslated = [];
      for (const key in dataGroupedBy) {
        labelsTranslated.push(t(`opportunities_drop_down.${key}`));
      }  

      const finalData = {
        labels: labelsTranslated,
        datasets,
      };

      setChartFinalData(finalData);
    };

    fillBarData();
  }, [barChartData,t ]);

  useEffect(() => {
    const fetchData = async () => {
      try {        
            const response = await fetch(api.address + 'observations/controls', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
            },
          });

        const data = await response.json();

        if (data && data.departments) {
          setUnitDropdownData(data.departments);        
        } else if (data.error && data.error === 'Forbidden') {
          console.log('Forbidden, redirecting to login page');
          navigate('/');
        } else {
          console.log('Empty data from data API');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [authToken, navigate]);

  useEffect(() => {
    // Initialize selectedUnits with all units
    if (unitDropdownData.length > 0) {
      setSelectedUnits(unitDropdownData.map((item) => {return item.name}));
    }
  }, [unitDropdownData]);


  const options = {
    plugins: {
        title: {
            display: true,
            text: t('labels.observations'),
        },     
        legend: {
            display: true            
        },
        datalabels: {
            formatter: function (value, context) { return value || null;  }
        }   
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
          beginAtZero: true,
          min: 0, // Set the minimum value for the y-axis
          ticks: {
            stepSize: 1, // Adjust the step size of the ticks
          },
        },
        x: {
            ticks: {
              // Rotate the x-axis labels vertically at a 90-degree angle
              rotation: 80,
              minRotation: 80,
              // Ensure all labels are displayed
              autoSkip: false,
            },
          },       
      },
      // Adjust the thickness and spacing of the bars
    indexAxis: 'x', // Use 'x' axis as the index axis
    barThickness: 'flex', // Set bar thickness to flex
    categorySpacing: 0, // Set category spacing to 0
    barPercentage: 1
  };

  const handleUnitSelectChange = (event) => {
    const selectedDepartments = event.target.value
    

    if (selectedDepartments.includes('all')) {
        setSelectedUnits(unitDropdownData.map((item) => {return item.name}));
    } else {
        setSelectedUnits(selectedDepartments);
    }
  
    // eslint-disable-next-line
        let filteredBarChartData = barChartData.filter((item) => {                 
            if(selectedDepartments.includes(item.department)) {              
                return item;
            }          
        });
        //console.log(filteredBarChartData);

        let dataGroupedBy = filteredBarChartData.reduce((accumulator, item) => {
                (accumulator[item.opportunity] = accumulator[item.opportunity] || []).push(item);
                return accumulator;
            }, {});     


        let labels = Object.keys(dataGroupedBy);
        let datasets = [
        {
          label: t('labels.performed'),
          data: [],
          backgroundColor: 'rgb(46, 118, 210, 0.7)',
          borderColor: 'rgb(46, 118, 210, 1)',
          borderWidth: 0.5

        },
        {
          label: t('labels.missed'),
          data: [],
          backgroundColor: 'rgb(46, 118, 210, 0.3)',
          borderColor: 'rgb(46, 118, 210, 1)',
          borderWidth: 0.5
        }
      ]

      labels.forEach((opportunity) => {
        let data = dataGroupedBy[opportunity].reduce((accumulator, item) => {
          Object.keys(item).forEach(key => {
            if (key !== 'opportunity') {
              accumulator[key] = (accumulator[key] || 0) + item[key];
            }
          });
          return accumulator;
        }, {});
       
        datasets[0].data.push(data.performed); //preformed
        datasets[1].data.push(data.missed); //missed
      });
      
      let labelsTranslated = [];
      for (const key in dataGroupedBy) {
        labelsTranslated.push(t(`opportunities_drop_down.${key}`));
      } 

      const finalData = {
        labels: labelsTranslated,
        datasets,
      };

      setChartFinalData(finalData);
  };

  const handleSelectAllToggle = () => {

    if (selectedUnits.length === unitDropdownData.length) {
      setSelectedUnits([]);      
      handleUnitSelectChange({target: {value: []}})
    } else {
      setSelectedUnits(unitDropdownData.map((item) => {return item.name}).reverse());
      handleUnitSelectChange({target: {value: unitDropdownData.map((item) => {return item.name})}})
    }
  };

  const handleDateRangeChange = (event) => {
    setSelectedDateRange(event.target.value);
  };

  const handleExportToExcel = () => {
    if (!chartFinalData || !chartFinalData.labels || !chartFinalData.datasets) {
      console.error('No data to export');
      return;
    }
  
    const selectedData = barChartData.filter(item => selectedUnits.includes(item.department));
      const removedProps = (obj, propsArray) => {
        propsArray.forEach(prop => delete obj[prop]);
        return obj;
      }
      
    const propsToDelete = ["total"];
   
    const fitToColumn = data => {
      const columnWidths = [];
      for (const property in data[0]) {
        columnWidths.push({
          wch: Math.max(property ? property.toString().length : 0,
          ...data.map(obj => obj[property] ? obj[property].toString().length : 0))
        });   
      }    
      return columnWidths; 
    };
    
    const dateTime = new Date().toISOString().replace(/[-:.]/g, '');
    const fileName = `observations_${dateTime}.xlsx`;
    
    const dataToExport = selectedData.map(row => {
      row.department = t(`departments_drop_down.${row.department}`);
      row.opportunity = t(`opportunities_drop_down.${row.opportunity}`);
      return removedProps(row, propsToDelete)
    });
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    
    worksheet['!cols'] = fitToColumn(dataToExport);
 
    worksheet['A1'] = { t: 's', v: t('labels.department') };
    worksheet['B1'] = { t: 's', v: t('labels.opportunity') };
    worksheet['C1'] = { t: 's', v: t('labels.missed') };
    worksheet['D1'] = { t: 's', v: t('labels.performed') };
    worksheet['E1'] = { t: 's', v: t('labels.timestamp') };
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, t('labels.observations'));
  
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  };

  const styles = isMobile ? mobileStyles : commonStyles;

  return (
    <div style={{ ...styles.wizardContainer }}>
      <div style={{ ...styles.wizardElements }}>
        <Sidebar location={location} isAdmin={isAdmin} isMobile={isMobile}/>
        <SoapyLogoSVG style={{ ...styles.logo }} />
        { barChartData ? <div style={{display: 'flex', flexDirection: 'column'}}>
          <FaArrowUpFromBracket style={{marginBottom:10, fontSize:18, marginLeft: -2}} onClick={handleExportToExcel}></FaArrowUpFromBracket>
          <div style={{display: 'flex', justifyContent:'space-between'}}>
          <FormControl style={{ ...styles.multiselect }} >
          <InputLabel htmlFor="departments-selectbox" style={{ left: -14, top: 6}}>{t('labels.care_unit')}</InputLabel>
          <Select
            multiple
            value={selectedUnits}
            onChange={handleUnitSelectChange}
            renderValue={(selected) => selected.join(', ')}
            input={<Input id="departments-selectbox"/>}
            sx={{fontSize: 12}}     
            style={{ color: 'rgba(0, 0, 0, 0.6)' }}
            MenuProps={{ disablePortal: true }}           
          >
             <MenuItem key="select-all" value="all" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              <Checkbox
                checked={selectedUnits.length === unitDropdownData.length}
                indeterminate={selectedUnits.length > 0 && selectedUnits.length < unitDropdownData.length}
                onChange={handleSelectAllToggle}
              />
              <ListItemText primary={t('labels.select_all')} />
            </MenuItem >
            {unitDropdownData.map((unit) => (
              <MenuItem key={unit.name} value={unit.name} sx={{fontSize: 12}} style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                <Checkbox checked={selectedUnits.includes(unit.name)} />
                <ListItemText primary={t(`departments_drop_down.${unit.name}`)} style={{fontSize:12}}/>
              </MenuItem>
            ))}
          </Select>
          </FormControl>
          <FormControl style={{ ...styles.multiselect }} >
            <InputLabel htmlFor="date-range-selectbox" style={{ left: -14, top: 6 }}>{t('labels.time_range')}</InputLabel>
            <Select
              value={selectedDateRange}
              onChange={handleDateRangeChange}
              input={<Input id="date-range-selectbox" />}
              sx={{ fontSize: 12 }}
              style={{ color: 'rgba(0, 0, 0, 0.6)' }}
              MenuProps={{ disablePortal: true }}
            >
              <MenuItem value={-7}>{t('labels.7_days')}</MenuItem>
              <MenuItem value={-14}>{t('labels.14_days')}</MenuItem>
              <MenuItem value={-30}>{t('labels.30_days')}</MenuItem>
            </Select>
          </FormControl> </div>
          </div> : ''}
        <div style={{height: 400,display: 'flex', justifyContent: 'center'}}>
            { barChartData ? <Bar data={chartFinalData} plugins={[ChartDataLabels]} options={options} /> : <CircularProgress style={{ marginTop:20 }}/> }
        </div>
      </div>
    </div>
  );
};

export default Report;
