import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { commonStyles, mobileStyles } from '../styles';
import { ReactComponent as SoapyLogoSVG } from '../graphics/soapy-logo.svg';
import {api} from '../api.js';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import Sidebar from './Sidebar.js';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';


const Access = ({isMobile}) => { 
    const { t } = useTranslation();
    const navigate = useNavigate();
    const authToken = localStorage.getItem('jwtToken');  
    const location = useLocation();
    const styles = isMobile ? mobileStyles : commonStyles;
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [userAccessId, setUserAccessId] = useState(null);
    // eslint-disable-next-line
    const [isAdmin, setIsAdmin] = useState(true);

    useEffect(() => {

        const fetchData = async () => {
          try {
            const response = await fetch(api.address + `access`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
              },
            });

            const data = await response.json();

            if (data && data.sign_up_id_admin && data.sign_up_id_user) {                 
                setUserAccessId(data.sign_up_id_user);        
                //set the data
            } else if (data.error && data.error === 'Forbidden') {
              console.log('Forbidden, redirecting to login page');
              navigate('/');
            } else {
              console.log('Empty data from data API');
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        fetchData();
      }, [authToken, navigate]);



     // Function to copy text to clipboard
     const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setSnackbarOpen(true); // Show Snackbar upon successful copy
            setTimeout(() => setSnackbarOpen(false), 2000); // Hide Snackbar after 3 seconds
        }).catch(err => {
            // Display an error message or handle the error case
            console.error('Failed to copy text to clipboard', err);
        });
    };

  
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false); // Close Snackbar when requested
    };

    const renderAccessPage = () => {
        return (
          <div style={{ ...styles.wizardContainer}}>
            <div style={{ ...styles.wizardElements }}>
            <Sidebar location={location} isAdmin={isAdmin} isMobile={isMobile}/>
              <SoapyLogoSVG style={{ ...styles.logo }} />
              {userAccessId ? <div>
                <div style={{display: 'flex', flexDirection: 'column', marginTop:20}}>
                    <Typography gutterBottom style={{fontSize:12}}>
                        {t('labels.user_registration')}
                    </Typography>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Box mr={1} p={1} border="1px solid #ccc" borderRadius="4px" style={{width:"80%"}}>
                        o.soapy.care/signup/{userAccessId}
                        </Box>
                        <Tooltip title={t('labels.copy_to_clipboard')}>
                            <IconButton onClick={() => copyToClipboard('o.soapy.care/signup/'+userAccessId)}>
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                     {/* Snackbar component */}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={2000}
                    onClose={handleCloseSnackbar}
                    message={t('labels.copied')}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                />
               </div> : <div style={{textAlign: 'center'}}><CircularProgress style={{ marginTop:20 }}/></div>}
            </div>
          </div>
        );  
      };
    
      return (
        <div>
        {renderAccessPage()}
        </div>
      );

}

export default Access;